<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
        xl="4"
      >
        <report-card-item
          :title="'Sản Phẩm (Góc/Tổng)'"
          :value="dashboardData.product_corner_total + ' / ' + dashboardData.product_total"
          :icon="'DatabaseIcon'"
          :links="[{text: 'Xem sản phẩm thuê', router: '/product-hired/list'},{text: 'Xem sản phẩm bán', router: '/product-sell/list'}, ]"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="4"
      >
        <report-card-item
          :title="'Tổng khách hàng'"
          :value="'' + dashboardData.client_total"
          :icon="'UsersIcon'"
          :links="[{text: 'Xem danh sách', router: '/customer-corner/list'}]"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        xl="4"
      >
        <report-card-item
          :title="'Tổng nhân viên'"
          :value="'' + dashboardData.user_total"
          :icon="'UsersIcon'"
          :links="[{text: 'Xem danh sách', router: '/user/list'}]"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          no-body
          class="custom-border-top-1px"
        >
          <b-card-header>
            <b-badge
              variant="light-primary"
              class="font-small-4 px-1 py-50"
            >
              SẢN PHẨM
            </b-badge>
            <div class="d-flex align-items-center">
              <v-select
                v-model="monthFilter"
                class="select-size-sm mr-25"
                label="name"
                :options="MONTH_OPTIONS"
                :searchable="false"
              />
              <v-select
                v-model="yearFilter"
                class="select-size-sm"
                label="name"
                :options="YEAR_OPTIONS"
                :searchable="false"
              />
            </div>
          </b-card-header>
          <vue-apex-charts
            :key="'dashboard-chart-product-' +keyRefreshComponent"
            :options="productChart.options"
            :series="productChart.series"
          />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          no-body
          class="custom-border-top-1px"
        >
          <b-card-header>
            <b-badge
              variant="light-primary"
              class="font-small-4 px-1 py-50"
            >
              KHÁCH HÀNG
            </b-badge>
            <div class="d-flex align-items-center">
              <v-select
                v-model="monthFilter"
                class="select-size-sm mr-25"
                label="name"
                :options="MONTH_OPTIONS"
                :searchable="false"
              />
              <v-select
                v-model="yearFilter"
                class="select-size-sm"
                label="name"
                :options="YEAR_OPTIONS"
                :searchable="false"
              />
            </div>
          </b-card-header>
          <vue-apex-charts
            :key="'dashboard-chart-customer-' +keyRefreshComponent"
            :options="customerChart.options"
            :series="customerChart.series"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardHeader, BCol, BRow,
} from 'bootstrap-vue'
import ReportCardItem from '@/views/overview/ReportCardItem.vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import useOverview from '@/views/overview/useOverview'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    ReportCardItem,
    VueApexCharts,
    vSelect,
    BCardHeader,
    BBadge,
  },
  computed: {
    chartOptionsProduct() {
      return this.chartOptions
    },
    chartOptionsCustomer() {
      return this.chartOptions
    },
  },
  watch: {
    dashboardChart(n) {
      if (!n) return
      const p = n.product
      const c = n.client
      // eslint-disable-next-line no-nested-ternary
      const xLabelFormat = !this.yearFilter ? 'yyyy' : this.monthFilter ? 'dd-MM' : 'MM-yy'

      this.customerChart.series[0].data = c.map(item => parseInt(item.total_product, 10))
      this.customerChart.options.xaxis.categories = c.map(item => item.time)
      this.customerChart.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1

      this.productChart.series[0].data = p.map(item => parseInt(item.total_product, 10))
      this.productChart.options.xaxis.categories = p.map(item => item.time)
      this.productChart.options.xaxis.labels.format = xLabelFormat
      this.keyRefreshComponent += 1
    },
  },
  created() {
    this.reportUserId = ''
    this.initFilter()
    this.fetchReportDashboard()
  },
  methods: {
    initFilter() {
      const m = new Date().getMonth() + 1
      const y = new Date().getFullYear()
      this.monthFilter = m > 9 ? m : `0${m}`
      this.yearFilter = y
    },
    handleRowClick() {
      this.$router.push({ name: 'notification-list' })
    },
  },
  setup() {
    return {
      ...useOverview(),
    }
  },
}
</script>
