import { ref, watch } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import http from '@/global/http'
import util from '@/global/util'

export default function useOverview() {
  const monthFilter = ref('')
  const yearFilter = ref('')

  const reportUserId = ref('')
  const dashboardData = ref({})
  const dashboardChart = ref(null)
  const keyRefreshComponent = ref(1000)

  const isReportMe = ref(false)

  const productChart = ref({
    series: [{
      name: 'Biểu đồ sản phẩm',
      data: [],
    }],
    options: {
      markers: {
        size: 4,
      },
      yaxis: {
        labels: {
          formatter: val => util.formatNumber(val),
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => util.formatNumber(val),
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        type: 'datetime',
        labels: {
          rotate: -45,
          rotateAlways: true,
          minHeight: 40,
          // hideOverlappingLabels: false,
        },
      },
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        height: 350,
        zoom: {
          enabled: false,
        },
        id: 'dashboard-chart-product',
      },
    },
  })
  const customerChart = ref({
    series: [{
      name: 'Biểu đồ khách hàng',
      data: [],
    }],
    options: {
      markers: {
        size: 4,
      },
      yaxis: {
        labels: {
          formatter: val => util.formatNumber(val),
        },
      },
      dataLabels: {
        enabled: true,
        formatter: val => util.formatNumber(val),
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        type: 'datetime',
        labels: {
          rotate: -45,
          rotateAlways: true,
          minHeight: 40,
          // hideOverlappingLabels: false,
        },
      },
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        height: 350,
        zoom: {
          enabled: false,
        },
        id: 'dashboard-chart-customer',
      },
    },
  })

  const historyLogs = ref([])
  const tableColumnsHistoryLogs = [
    {
      label: 'Loại',
      key: 'type',
    },
    {
      label: 'Nội dung',
      key: 'content',
    },
    {
      label: 'Thời gian',
      key: 'time',
    },
  ]

  const getDateRange = () => {
    if (!yearFilter.value) return { from: '', to: '' }

    return {
      from: !monthFilter.value ? new Date(yearFilter.value, 0, 1)
        : new Date(yearFilter.value, parseFloat(monthFilter.value - 1), 1),
      to: !monthFilter.value ? new Date(yearFilter.value, 12, 0) : new Date(yearFilter.value, monthFilter.value, 0, 23, 59, 59),
    }
  }

  const fetchReportDashboard = cb => axiosIns.get(`/api.report/${isReportMe.value ? 'me' : 'dashboard'}${http.buildQuery({
    user_id: reportUserId.value,
  })}`).then(res => {
    dashboardData.value = res.data.data
    return cb && cb(res.data.data)
  })

  const fetchReportChart = (filter = {}, cb) => {
    const { from, to } = getDateRange()

    return axiosIns.get(`/api.report/chart${isReportMe.value ? '/me' : ''}${http.buildQuery({
      from: http.getISODate(filter.from || from),
      to: http.getISODate(filter.to || to),
      user_id: reportUserId.value,
      // eslint-disable-next-line no-nested-ternary
      time: !yearFilter.value ? 'year' : monthFilter.value ? 'day' : 'month',
    })}`).then(res => {
      dashboardChart.value = res.data.data
      return cb && cb(res.data.data)
    })
  }

  watch([yearFilter, monthFilter], () => {
    fetchReportChart()
  })

  return {
    reportUserId,
    dashboardData,
    dashboardChart,
    keyRefreshComponent,
    productChart,
    customerChart,
    historyLogs,
    tableColumnsHistoryLogs,
    yearFilter,
    monthFilter,
    isReportMe,
    fetchReportDashboard,
    fetchReportChart,
  }
}
