<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <b-avatar
        rounded
        size="48"
        variant="light-primary"
      >
        <feather-icon
          :icon="icon"
          size="24"
        />
      </b-avatar>
      <div class="d-flex flex-column text-right">
        <h4 class="text-primary font-weight-bolder">
          {{ value }}
        </h4>
        <div class="font-small-3">
          {{ title }}
        </div>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-between flex-wrap">
      <router-link
        v-for="(link, idx) in links"
        :key="'dashboard-card-link-' + idx"
        class="w-50"
        :to="link.router"
      >
        {{ link.text }}
      </router-link>
    </div>
  </b-card>
</template>

<script>
import { BAvatar, BCard } from 'bootstrap-vue'

export default {
  components: {
    BAvatar, BCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
